<template>
  <div>
    <a-table rowKey="id" size="small" :columns="columns" :data-source="dataItems" :pagination="false">
      <div slot="number" slot-scope="value, item">
        <a @click="detial(item)">{{ value }}</a>
      </div>
      <div slot="action" slot-scope="value, item, index">
        <a-button-group size="small">
          <a-button @click="detial(item)">详情</a-button>
          <a-popconfirm v-if="item.status == 'in_progress'" title="确定关闭吗?" @confirm="close(item)">
            <a-button type="primary">关闭工单</a-button>
          </a-popconfirm>
          <a-button v-if="item.status === 'in_progress'" type="primary" @click="openRequirement(item)"> 物料需求 </a-button>
          <a-button
            v-if="item.status === 'in_progress' || item.status === 'completed' || item.status === 'closed'"
            type="primary"
            @click="openStockInModal(item)"
          >
            半成品入库
          </a-button>
        </a-button-group>
      </div>
    </a-table>

    <StockInModal v-model="stockInModalVisible" title="半成品入库" :item="targetItem" @update="update" />
    <RequirementModal v-model="requirementModalVisible" :productionOrderItem="targetItem" @cancel="list()" />
  </div>
</template>

<script>
import { productionOrderSubProductionOrders, productionOrderClose } from "@/api/production";

export default {
  components: {
    StockInModal: () => import("./StockInModal"),
    RequirementModal: () => import("@/components/RequirementModal"),
  },
  props: ["visible", "item"],
  data() {
    return {
      dataItems: [],
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          width: 60,
          fixed: "left",
          customRender: (value, item, index) => index + 1,
        },
        {
          title: "生产工单号",
          dataIndex: "number",
          width: 180,
          fixed: "left",
          scopedSlots: { customRender: "number" },
        },
        {
          title: "类型",
          dataIndex: "type_display",
          width: 100,
        },
        {
          title: "状态",
          dataIndex: "status_display",
          width: 100,
        },
        {
          title: "产品名称",
          dataIndex: "goods_name",
        },
        {
          title: "产品编号",
          dataIndex: "goods_number",
        },
        {
          title: "计划数量",
          dataIndex: "total_quantity",
          width: 100,
        },
        {
          title: "完成数量",
          dataIndex: "quantity_produced",
          width: 100,
        },
        {
          title: "计划开始时间",
          dataIndex: "planned_start_time",
          width: 180,
        },
        {
          title: "计划完成时间",
          dataIndex: "planned_end_time",
          width: 180,
        },
        {
          title: "操作",
          dataIndex: "action",
          width: 300,
          scopedSlots: { customRender: "action" },
        },
      ],

      targetItem: {},
      stockInModalVisible: false,
      requirementModalVisible: false,
    };
  },
  methods: {
    list() {
      productionOrderSubProductionOrders({ id: this.item.id }).then((data) => {
        this.dataItems = data;
      });
    },
    detial(item) {
      this.$router.push({ path: "/production/detial", query: { id: item.id } });
    },
    close(item) {
      productionOrderClose({ id: item.id }).then((data) => {
        this.$message.success("关闭成功");
        this.dataItems = this.$functions.replaceItem(this.dataItems, data);
      });
    },
    openStockInModal(item) {
      this.targetItem = item;
      this.stockInModalVisible = true;
    },
    openRequirement(item) {
      this.targetItem = item;
      this.requirementModalVisible = true;
    },
    update(item) {
      this.dataItems = this.$functions.replaceItem(this.dataItems, item);
    },
  },
  mounted() {
    this.list();
  },
};
</script>

<style scoped></style>
